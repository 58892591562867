import { Typography, Stack, DialogContent } from "@mui/material";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  title: string;
  subTitle: string;
  onClose?: () => void;
}>;

export const SparxDialog = ({ title, subTitle, children }: Props) => {
  return (
    <>
      <DialogContent sx={{ p: 4 }}>
        <Stack gap={2}>
          <Stack alignItems="left" spacing={2} sx={{ flex: 1 }}>
            <Typography variant="subtitle1">{title}</Typography>
            <Typography variant="body1" textAlign="left">
              {subTitle}
            </Typography>
          </Stack>
          <Stack spacing={3}>{children}</Stack>
        </Stack>
      </DialogContent>
    </>
  );
};
