import { useState } from "react";
import { Card } from "@mui/material";
import { SparxCycle } from "@/interface";
import { CycleInfo } from "./CycleInfo";
import { CycleDetails } from "./CycleDetails";

type Props = {
  sparxCycle: SparxCycle;
  isFirstcycle?: boolean;
  onAllocate?: () => void;
};

export const CycleRenderer = ({
  sparxCycle,
  isFirstcycle,
  onAllocate,
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Card
      sx={{
        background: (theme) => theme.palette.background.default,
        border: 0,
        px: 2,
        borderBottom: 1,
        borderColor: (theme) =>
          sparxCycle.state === "ACTIVE"
            ? theme.palette.primary.light
            : theme.palette.grey[100],
      }}
      elevation={0}
    >
      <CycleInfo
        cycle={sparxCycle}
        expandd={expanded}
        onExpand={() => setExpanded(!expanded)}
        onAllocate={onAllocate}
        isFirstcycle={isFirstcycle}
      />
      <CycleDetails sparxCycle={sparxCycle} expanded={expanded} />
    </Card>
  );
};
