import { useState, MouseEvent } from 'react';
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Join } from "./Join";
import { Joined } from "./Joined";
import { EnrollmentData } from "@/interface";
import { Rejoin } from "./Rejoin";
import { Edit } from "../../header/Edit";
import { Delete } from "../../header/Delete";
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { InitiativeStatus } from "@/enums";
import { useUserState } from "@/state";

type Props = {
  enrolled?: EnrollmentData;
  dropped?: EnrollmentData;
  mutate: () => void;
  isOwner: boolean;
  status: InitiativeStatus | undefined;
};

export const Actions = ({
  enrolled,
  dropped,
  mutate,
  isOwner,
  status,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const canJoin = !enrolled && !dropped;
  const { userData } = useUserState();
  const isAdmin = userData?.roles?.includes("ADMIN");
  const showOverflowMenu = (isOwner || isAdmin) && ![InitiativeStatus.DELETED, undefined].includes(status);
  const showEditButton = isOwner || isAdmin;
  const showDeleteButton = isAdmin;

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <>
      <Join display={canJoin} mutate={mutate} />
      <Rejoin enrollment={dropped} mutate={mutate} />
      <Joined enrollment={enrolled} mutate={mutate} />
      {showOverflowMenu && (
        <>
          <IconButton onClick={handleClick}>
            <MoreHorizRoundedIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {showEditButton && (
              <MenuItem onClick={handleClose}>
                <Edit isOwner={isOwner} isAdmin={isAdmin} />
              </MenuItem>
            )}
            {showDeleteButton && (
              <MenuItem onClick={handleClose}>
                <Delete isAdmin={isAdmin} />
              </MenuItem>
            )}
          </Menu>
        </>
      )}
    </>
  );
};
