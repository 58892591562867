import { Reaction, ReactionType } from "@/interface";
import { Emoji } from "./Emoji";
import { alpha, Box, styled, Typography } from "@mui/material";
import { useUserState } from "@/state";

type Props = {
  reactions: Reaction[];
  onReact: (reaction: ReactionType) => void;
};

const StyledBox = styled(Box)<{
  ownerState: {
    active?: boolean;
  };
}>(({ theme, ownerState }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  padding: theme.spacing(0.2, 0.8),
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: theme.spacing(4),
  cursor: "pointer",
  ...(ownerState.active && {
    borderColor: theme.palette.primary.light,
    background: alpha(theme.palette.primary.main, 0.08),
  }),
  ":hover": {
    background: ownerState?.active
      ? alpha(theme.palette.primary.main, 0.2)
      : theme.palette.action.hover,
  },
}));

export const ReactionsRenderer = ({ reactions, onReact }: Props) => {
  const { userData } = useUserState();
  return (
    <>
      {reactions?.map(({ reactionType, users }) => {
        return (
          <StyledBox
            key={reactionType}
            ownerState={{ active: users.has(userData?.id ?? "") }}
            onClick={() => onReact?.(reactionType)}
          >
            <Emoji emojiType={reactionType} sx={{ fontSize: 18 }} />
            <Typography variant="subtitle2">{users.size}</Typography>
          </StyledBox>
        );
      })}
    </>
  );
};
