import { Fragment, useEffect } from "react";
import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { DirectionFilterEnum, TransactionTableCycleData } from "@/interface";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { useTransactionsState, getInitialExpandedCycles } from "@/state";
import CycleContainer from "./CycleContainer";

type Props = {
  cycleTransactions: TransactionTableCycleData[];
  transactionsType: DirectionFilterEnum;
  mutate: (() => void) | undefined;
};

export const CollapsibleCycleSection = ({
  cycleTransactions,
  transactionsType,
  mutate,
}: Props) => {
  const { expandedCyclesSpent, expandedCyclesReceived, expandedCyclesAll, setState } = useTransactionsState();
  const theme = useTheme();

  //Set initial expanded cycles of the expandedCyclesSpent and expandedCyclesReceived state
  useEffect(() => {
    const initialExpandedCycles = getInitialExpandedCycles(cycleTransactions);
  
    if (transactionsType === DirectionFilterEnum.SPENT && Object.keys(expandedCyclesSpent).length === 0) {
      setState({
        expandedCyclesSpent: initialExpandedCycles
      });
    }
    if (transactionsType === DirectionFilterEnum.RECEIVED && Object.keys(expandedCyclesReceived).length === 0) {
      setState({
        expandedCyclesReceived: initialExpandedCycles
      });
    }

    if (transactionsType === DirectionFilterEnum.ALL && Object.keys(expandedCyclesAll).length === 0) {
      setState({
        expandedCyclesAll: initialExpandedCycles
      });
    }
  }, [cycleTransactions, transactionsType, expandedCyclesSpent, expandedCyclesReceived, expandedCyclesAll, setState]);

  const toggleCycle = (cycleSymbol: string) => {

    if (transactionsType === DirectionFilterEnum.SPENT) {
      const newExpandedCyclesSpent = {
        ...expandedCyclesSpent,
        [cycleSymbol]: !expandedCyclesSpent[cycleSymbol],
      };
      setState({
        expandedCyclesSpent: newExpandedCyclesSpent,
      });
    } 

    if (transactionsType === DirectionFilterEnum.RECEIVED) {
      const newExpandedCyclesReceived = {
        ...expandedCyclesReceived,
        [cycleSymbol]: !expandedCyclesReceived[cycleSymbol],
      };
      setState({
        expandedCyclesReceived: newExpandedCyclesReceived,
      });
    }

    if (transactionsType === DirectionFilterEnum.ALL) {
      const newExpandedCyclesAll = {
        ...expandedCyclesAll,
        [cycleSymbol]: !expandedCyclesAll[cycleSymbol],
      };
      setState({
        expandedCyclesAll: newExpandedCyclesAll,
      });
    }
  };


  return (
    <TableBody>
      {cycleTransactions.map((cycle) => (
        <Fragment key={cycle.symbol}>
          <TableRow key={cycle.symbol} sx={{cursor: "pointer", "&:hover": {backgroundColor: "rgba(0, 0, 0, 0.04)"}}} onClick={() => toggleCycle(cycle.symbol)}>
            <TableCell colSpan={4}>
              <Typography variant="subtitle2" sx={{color: theme.palette.text.secondary }}>{cycle.symbol}</Typography>
            </TableCell>
            <TableCell align="right" colSpan={1}>
              <IconButton
                aria-label="expand row"
                size="small"
              >
                {(transactionsType === DirectionFilterEnum.SPENT
                  ? expandedCyclesSpent[cycle.symbol]
                  : transactionsType === DirectionFilterEnum.RECEIVED
                    ? expandedCyclesReceived[cycle.symbol]
                    : expandedCyclesAll[cycle.symbol]) ? (
                  <KeyboardArrowUpRoundedIcon />
                ) : (
                  <KeyboardArrowDownRoundedIcon />
                )}
              </IconButton>
            </TableCell>
          </TableRow>
          <CycleContainer 
            cycle={cycle} 
            isExpanded={transactionsType === DirectionFilterEnum.SPENT 
              ? expandedCyclesSpent[cycle.symbol] 
              : transactionsType === DirectionFilterEnum.RECEIVED 
                ? expandedCyclesReceived[cycle.symbol]
                : expandedCyclesAll[cycle.symbol]
            } 
            mutate={mutate}
          />
        </Fragment>
      ))}
    </TableBody>
  );
};