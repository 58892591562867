import { ErrorCode } from "react-dropzone";

export type AllocationStatus = "ACTIVE" | "INACTIVE" | "CLOSED";

export type Distribution = {
  lastUpdated: string;
  assigned: number;
  total: number;
};

export interface FileError {
  message?: string;
  details?: string[];
  code?: ErrorCode | string;
}

export type Allocation = {
  employees: string;
  individualSparx: number | string;
  totalSparx: string | number;
};

type AllocationData = {
  individual: number;
  total: number;
};

export type SparxAllocation = {
  mdp: AllocationData;
  peer: AllocationData;
  leaders: { total: number };
};

export type SparxFile = {
  name: string;
  errors: string[];
  warnings: string[];
};

export type SparxCycle = {
  file?: SparxFile;
  distribution: Distribution;
  overallAllocation: SparxAllocation;
  //
  name: string;
  symbol: string;
  state: AllocationStatus;
  allocationFile: string;
  bootstrapped: boolean;
  startDate: string;
  endDate: string;
  mdpTokenAllocation: number;
  peerTokenAllocation: number;
};

export enum AllocationStep {
  ALLOCATE,
  REVIEW,
}
