import { Box, Button, ButtonProps } from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';

type Props = {
  toggeled: boolean;
} & ButtonProps;

export const MessageToggle = ({ toggeled, ...rest }: Props) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      {toggeled ? (
        <Button variant="text" color="error" {...rest}>
          Cancel
        </Button>
      ) : (
        <Button variant="text" startIcon={<AddRoundedIcon />} {...rest}>
          Leave message
        </Button>
      )}
    </Box>
  );
};
