import { useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import {
  Container,
  Dialog,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSparxState } from "@/state";
import { API_ENDPOINTS, ROUTES } from "@/config";
import { useCalculatePaddingTop } from "@/hooks";
import { SubnavigationBar } from "@/components";
import { UserAutocomplete } from "../common/UserAutoComplete";
import { AssignSparxForm } from "../common/AssignSparxForm";
import { QuestionsCard } from "../common/QuestionsCard";
import { SuccessDialog } from "../common/SuccessDialog";
import { SparxMessageWithToggle } from "../common/SparxMessageWithToggle";
import { BalanceCard } from "../BalanceCard";
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';

export const SendSparx = () => {
  const paddingTop = useCalculatePaddingTop();
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { setReset } = useSparxState();
  const { mutate } = useSWRConfig();

  const onClose = () => {
    setOpenDialog(false);
    mutate(API_ENDPOINTS.tokens.sparx_transactions);
  }

  useEffect(() => {
    return () => {
      setReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SubnavigationBar 
        breadcrumbs={[
          { label: "Sparx", href: ROUTES.sparx, icon: <AutoAwesomeRoundedIcon /> },
          { label: "Send Sparx", href: ROUTES.send_sparx },
        ]}
      />
      <Container>
        <Grid container spacing={3} sx={{mt: paddingTop}}>
          <Grid item xs={12} md={8}>
            <Stack spacing={2}>
              <Typography variant="h6">Select Recipients</Typography>
              <UserAutocomplete />
              <AssignSparxForm />
              <SparxMessageWithToggle setOpenDialog={setOpenDialog} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <BalanceCard />
            <QuestionsCard />
          </Grid>
        </Grid>
        <Dialog
          maxWidth="sm"
          open={openDialog}
          fullScreen={fullScreen}
          onClose={onClose}
          sx={{
            "& .MuiDialog-paperFullScreen": {
              borderRadius: theme.spacing(0),
            },
          }}
        >
          <SuccessDialog onClose={onClose} />
        </Dialog>
      </Container>
    </>
  );
};
