import { useMemo } from "react";
import {Stack} from "@mui/material";
import { EmptyTransactionsCard } from "../common/EmptyTransactionsCard";
import { TransactionsTable } from "../common/TransactionsTable";
import { DirectionFilterEnum, TransactionTableCycleData } from "@/interface";
import { useTransactionsState } from "@/state";
import { filterTransactions } from "@/utils";

type Props = {
  mutate: () => void
}

export const SpentTransactions = ({ mutate }: Props) => {
  const { spentTransactions, selectedOwnershipFilter } = useTransactionsState();

  const filteredTransactions: TransactionTableCycleData[] = useMemo(() => { 
    return filterTransactions(spentTransactions, selectedOwnershipFilter);
  }, [spentTransactions, selectedOwnershipFilter]);

  if (!filteredTransactions || filteredTransactions?.length === 0) return <EmptyTransactionsCard type={DirectionFilterEnum.SPENT} />;

  return (
    <Stack pt={2}>
      <TransactionsTable cycleTransactions={filteredTransactions} transactionsType={DirectionFilterEnum.SPENT} mutate={mutate}/>
    </Stack>
  );
};