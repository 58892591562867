import {
  FilterOption,
  KeyValuePair,
} from "@/interface";

export const convertEnumToFilterOptions = (enumObj: Record<string, string>): FilterOption[] => {
  return Object.keys(enumObj).map(key => ({
    key: key,
    value: enumObj[key]
  }));
};

export const convertEnumKeyValuePair = <T extends Record<string, string>>(enumObj: T, enumKey: keyof T): KeyValuePair => {
  return {
    key: enumKey as string,
    value: enumObj[enumKey]
  };
};

export const createFilterObject = <T extends Record<string, string>>(filterValue: T[keyof T], enumObj: T): { [key: string]: string } => {
  // Find the key corresponding to the given value
  const key = (Object.keys(enumObj) as Array<keyof T>).find(k => enumObj[k] === filterValue);
  
  if (key) {
    return { [key]: filterValue };
  }
  return {};
}