import { Card, styled } from "@mui/material";
import { Reward } from "@/interface";
import { Shoutout } from "./Shoutout";
import { Transaction } from "./Transaction";
import { SkeletonCard } from "./SkeletonCard";

type Props = {
  isLoading: boolean;
  reward: Reward;
};

const StyledCard = styled(Card)(({ theme }) => ({
  background: theme.palette.background.default,
  boxShadow: theme.shadows[3],
  padding: theme.spacing(0.5),
  border: `1px solid ${theme.palette.divider}`,
  ".media": {
    height: 175,
    background: "linear-gradient(1deg, #F6F7F9 2.36%, #E3EBF8 99.09%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.spacing(1),
  },
}));

export const RewardCard = ({ reward, isLoading }: Props) => {

  if (isLoading)
    return (
      <StyledCard>
        <SkeletonCard />
      </StyledCard>
    );

  return (
    <StyledCard>
      {reward?.type === "shoutout" && <Shoutout reward={reward} />}
      {reward?.type === "transaction" && <Transaction reward={reward} />}
    </StyledCard>
  );
};
