import {
  TableCell,
  TableRow,
  Checkbox,
  Typography,
  Stack,
  styled,
} from "@mui/material";
import { AutoAwesomeRounded } from "@mui/icons-material";
import { UserAvatar, UserInfo } from "@/components";
import { Contributor } from "@/interface";
import { useBulkSparxState } from "@/state";
import { AmountField } from "../AmountField";

type Props = {
  contributor: Contributor;
  isItemSelected: boolean;
  onSelect: (userId: string) => void;
  onRowClick: (userId: string) => void;
};

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  justifyContent: "flex-end",
  alignItems: "center",
  color: theme.palette.text.disabled,
}));

export const RowRenderer = ({
  contributor,
  isItemSelected,
  onSelect,
  onRowClick,
}: Props) => {
  const { setAmount } = useBulkSparxState();
  const {
    numberOfContributions,
    totalSparxReceivedFromSender,
    amount,
    user,
    error,
  } = contributor;

  return (
    <TableRow
      hover
      onClick={() => onRowClick(user.id)}
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
      sx={{
        cursor: "pointer",
        borderBottom: (theme) => `1px solid ${theme.palette.grey[50]}`,
      }}
    >
      <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
        <Checkbox
          size="small"
          color="secondary"
          checked={isItemSelected}
          onClick={() => onSelect(user.id)}
        />
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        padding="none"
      >
        <UserAvatar user={user}>
          <UserInfo user={user} />
        </UserAvatar>
      </TableCell>
      <TableCell align="right">
        <Typography variant="subtitle2" color="blue">
          {numberOfContributions}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <StyledStack direction="row">
          <AutoAwesomeRounded sx={{ fontSize: 12 }} />
          <Typography variant="body2">
            {totalSparxReceivedFromSender}
          </Typography>
        </StyledStack>
      </TableCell>
      <TableCell align="right">
        <AmountField
          amount={amount}
          error={error}
          setAmount={(value) => setAmount(user.id, value)}
        />
        {error && (
          <Typography variant="subtitle2" color="error">
            {error}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};
