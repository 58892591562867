import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { AllocationTable } from "../cycle/AllocationTable";
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import { Allocation, AllocationStep } from "@/interface";
import { useActivateCycle } from "@/hooks/api";
import { LoadingButton } from "@mui/lab";
import { useUIActionsState } from "@/state";

type Props = {
  symbol: string;
  step: AllocationStep;
  allocations: Allocation[];
  onBack: () => void;
  onDone: () => void;
};

export const Review = ({
  step,
  allocations,
  symbol,
  onBack,
  onDone,
}: Props) => {
  const { setSnackbarOpen } = useUIActionsState();
  const { trigger, isMutating } = useActivateCycle(symbol);
  if (step !== AllocationStep.REVIEW) return;

  const onActivate = async () => {
    try {
      await trigger();
    } catch (error) {
      setSnackbarOpen({
        variant: "error",
        isOpen: true,
        message: (error as Error).message,
        hasFooter: false,
      });
    }  
    onDone();
  };

  return (
    <>
      <DialogTitle sx={{ p: 2 }}>
        <Stack alignItems="center" spacing={1}>
          <Typography variant="h5">Review Sparx allocations</Typography>
          <Typography textAlign="center">
            Please ensure the Sparx allocation is correct before proceeding.
            Once confirmed, no edits to the allocation will be possible.
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ mt: { md: 4, sx: 2 } }}>
        <AllocationTable allocations={allocations} secondary />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={onBack}>
          Back
        </Button>
        <LoadingButton
          loading={isMutating}
          variant="contained"
          color="primary"
          endIcon={<CachedRoundedIcon />}
          onClick={onActivate}
          disabled={isMutating}
        >
          Confirm & start cycle
        </LoadingButton>
      </DialogActions>
    </>
  );
};
