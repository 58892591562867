import { addShoutReactions, deleteShoutReaction, replaceReactionId } from "@/utils";
import { useAddReaction } from "./useAddReaction.ts";
import { useDeleteReaction } from "./useDeleteReaction.ts";
import { ReactionType, ShoutoutData } from "@/interface/ShoutoutData";

export const useReactions = (setShoutouts: React.Dispatch<React.SetStateAction<ShoutoutData[]>>) => {
  const { trigger: addReactionTrigger } = useAddReaction();
  const { trigger: deleteReactionTrigger } = useDeleteReaction();

  const addReaction = (userId: string, shoutoutId: string, reactionType: ReactionType) => {
    const reactionId = crypto.randomUUID();

    // Optimistically add the reaction
    setShoutouts((prevShoutouts) =>
      addShoutReactions(prevShoutouts, userId, shoutoutId, reactionId, reactionType)
    );

    addReactionTrigger({ data: { userId, shoutoutId, reactionType } })
      .then((res) => {
        // Replace temporary reaction ID with the real one from the response
        setShoutouts((prevShoutouts) =>
          replaceReactionId(prevShoutouts, shoutoutId, reactionId, reactionType, res.id)
        );
      })
      .catch(() => {
        // Revert optimistic update in case of an error
        setShoutouts((prevShoutouts) =>
          deleteShoutReaction(prevShoutouts, shoutoutId, reactionId, reactionType)
        );
      });
  };

  const deleteReaction = (userId: string, shoutoutId: string, reactionId: string, reactionType: ReactionType) => {
    // Optimistically delete the reaction
    setShoutouts((prevShoutouts) =>
      deleteShoutReaction(prevShoutouts, shoutoutId, reactionId, reactionType)
    );

    deleteReactionTrigger({ reactionId })
      .catch(() => {
        // Revert deletion on error
        setShoutouts((prevShoutouts) =>
          addShoutReactions(prevShoutouts, userId, shoutoutId, reactionId, reactionType)
        );
      });
  };

  return { addReaction, deleteReaction };
};
