import { useBulkSparxState } from "@/state";
import { Box, Drawer as MuiDrawer } from "@mui/material";
import { Profile } from "./Profile";
import { Amount } from "./Amount";
import { ContributionList } from "./ContributionList";
import { Header } from "./Header";
import { useContributorDetails } from "@/hooks/api";
import { byInitiative } from "@/utils";
import { ContributionListSkeleton } from "./ContributionListSkeleton";

export const Drawer = () => {
  const { contributor, setState } = useBulkSparxState();
  const { data, isLoading } = useContributorDetails(contributor?.user.id ?? "");

  const onClose = () => setState({ contributor: undefined });

  return (
    <MuiDrawer
      anchor="right"
      open={!!contributor}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 470,
          background: (theme) => theme.palette.background.default,
        },
      }}
    >
      <Box sx={{ overflow: "auto" }}>
        <Header />
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{ px: 3, py: 1 }}
        >
          <Profile />
          <Amount
            totalContributions={data?.totalContributions}
            isLoading={isLoading}
          />

          {isLoading ? (
            <ContributionListSkeleton />
          ) : (
            <ContributionList
              contributor={contributor}
              data={byInitiative(data)}
            />
          )}
        </Box>
      </Box>
    </MuiDrawer>
  );
};
