import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export const untilMonths = (date: Date) => {
  return dayjs(date).format("D MMM").toString();
};

export const endCycleDateFormat = (date: Date) => {
  return dayjs(date).format("MMMM D, YYYY").toString();
};

export const ensureHttpPrefix = (url: string) =>
  !url.startsWith("https://") ? `http://${url}` : url;

export const elapsedTime = (date: string) => {
  return dayjs(new Date(date)).fromNow();
};

export const formatDate = (date: Date) =>
  date.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

export const formatDateLongMonth = (date: Date) => dayjs(new Date(date)).format("D MMM YYYY");
