import { Category, UserData } from ".";

export type ApiReaction = {
  id: string;
  userId: string;
  createdAt?: string;
};

export type RemoteReactions = Record<ReactionType, ApiReaction[]>;

export type ApiReactionResponse = {
  id: string;
  shoutoutId: string;
  userId: string;
  createdAt: string;
  type: ReactionType;
};

export interface ShoutoutData {
  id: string;
  author: UserData;
  receivers: ShoutoutReceived[];
  category: Category;
  public: boolean;
  details: string;
  createdAt: string;
  updatedAt?: string;
  deletedAt?: string;
  reactions: RemoteReactions;
}

export interface ShoutoutPaginationData {
  totalItems: number;
  items: ShoutoutReceived[] | ShoutoutData[];
  page: number;
  size: number;
}

export interface ShoutoutReceived {
  id: string;
  userId: number;
  shoutout: ShoutoutData;
  public: boolean;
  receiver: UserData;
}

export interface ShoutoutBodyRequest {
  fields: {
    receivers: string[];
    details: string;
    public: boolean;
  };
}

export interface ShoutoutEditBodyRequest {
  details: string;
  public: boolean;
}

export enum ShoutoutsView {
  RECEIVED,
  GIVEN,
}

export enum ReactionType {
  LOVE = "LOVE",
  LIKE = "LIKE",
  CLAP = "CLAP",
  WELL_DONE = "WELL_DONE",
  APPRECIATE = "APPRECIATE",
}

export type Reaction = {
  reactionType: ReactionType;
  users: Map<string, ApiReaction>;
};
