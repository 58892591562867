import { PropsWithChildren, useEffect } from "react";
import { Toolbar, Typography, 
  // Button, 
  Box } from "@mui/material";
import { useBulkSparxState } from "@/state";
// import CallSplitRoundedIcon from '@mui/icons-material/CallSplitRounded';

type Props = PropsWithChildren<{
  selected: readonly string[];
}>;

export const EnhancedTableToolbar = ({ selected, children }: Props) => {
  const { setState, 
    // setOpenDistributeModal, 
    contributors } =
    useBulkSparxState();
  const numSelected = selected.length;
  // const onClick = () => setOpenDistributeModal();

  useEffect(() => {
    setState({
      selectedContributors: contributors.filter((contributor) =>
        selected.includes(contributor.user.id)
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      {numSelected > 0 ? (
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          width={1}
          gap={0.5}
        >
          <Typography variant="subtitle1" component="div">
            {numSelected} 
          </Typography>
          <Typography variant="body1" color="text.secondary" component="div">
            selected
          </Typography>
        </Box>
      ) : (
        children
      )}
    </Toolbar>
  );
};
