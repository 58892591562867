
import { NoDataMessage } from "@/components"
import { ROUTES } from "@/config"
import { useNavigate } from "react-router-dom"

type Props = {
  cycleName: string;
  isCurrentCycle: boolean;
}

export const EmptyCycleCard = ({cycleName, isCurrentCycle} : Props) => {
  const navigate = useNavigate();

  return (
    isCurrentCycle ? (
      <NoDataMessage
        colSpan={5}
        title={"No transactions in the current cycle"}
        message={`You can be the first to start sending Sparx`}
        buttonText={"Send Sparx"}
        navigate={navigate}
        route={ROUTES.send_sparx}
        noPadding
        buttonClick={() => navigate(ROUTES.send_sparx)}
      />
    ) :
    (<NoDataMessage
        colSpan={5}
        title={"No transactions"}
        message={`You didn't spend or receive Sparx in ${cycleName}`}
        noPadding
      />)
  )
}