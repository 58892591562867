import { LoadingButton } from "@mui/lab";
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import { useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useReject } from "@/hooks/api";
import { useParams } from "react-router-dom";
import { CustomDialog } from "@/components";

type Props = {
  mutate: () => void;
};

export const Reject = ({ mutate }: Props) => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const { trigger, isMutating } = useReject(id ?? "");

  const onClose = () => setOpen(false);

  const onReject = () =>
    trigger({ reviewNote: message }).then(() => {
      setMessage("");
      onClose();
      mutate();
    });

  return (
    <>
      <LoadingButton
        startIcon={<ThumbDownRoundedIcon />}
        color="error"
        onClick={() => setOpen(true)}
      >
        Reject
      </LoadingButton>
      <CustomDialog open={open} onClose={onClose}>
        <DialogTitle textAlign="center">Reject Initiative?</DialogTitle>
        <DialogContent>
          <DialogContentText textAlign="center" mb={2}>
            Are you sure you want to reject this initiative? This will notify
            the initiative owner.
          </DialogContentText>
          <TextField
            value={message}
            label="Rejection message"
            variant="outlined"
            size="small"
            multiline
            rows={4}
            fullWidth
            helperText="Enter a reason for the rejection "
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <LoadingButton
            loading={isMutating}
            onClick={onReject}
            variant="contained"
            color="error"
            disabled={!message}
          >
            Reject
          </LoadingButton>
        </DialogActions>
      </CustomDialog>
    </>
  );
};
