import { Box } from "@mui/material";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { CustomSnackbar, FloatingHelpButton } from "@/components";
import { useUIActionsState, useUserState } from "../state";
import { Shell } from "./common/Shell";
import { useBalance, useReviewers } from "@/hooks/api";
import { ErrorPage } from "./Error";
import { SendSparxModal } from "./common/sparx/SendSparxModal";

const Layout = () => {
  useBalance();
  const { me } = useUserState();
  const { isErrorPageOpen } = useUIActionsState();

  /**
   * Init reviewers
   */
  useReviewers();

  useEffect(() => {
    me();
  }, [me]);

  if (isErrorPageOpen) {
    return (
      <>
        <Shell />
        <Box component="main" sx={{ height: "100vh" }}>
          <ErrorPage />
        </Box>
      </>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex", pb: 4 }}>
        <Shell />
        <Box sx={{ flexGrow: 1, height: 1 }}>
          <Outlet />
        </Box>
      </Box>
      <SendSparxModal />
      <CustomSnackbar />
      <FloatingHelpButton />
    </>
  );
};

export { Layout };
