import {
  Box,
  Button,
  CardContent,
  Grid,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import { CycleStatus } from "./CycleStatus";
import { SparxCycle } from "@/interface";
import { formatDate } from "@/utils";

type Props = {
  cycle: SparxCycle;
  expandd: boolean;
  onExpand: () => void;
  onAllocate?: () => void;
  isFirstcycle?: boolean;
};

export const CycleInfo = ({
  cycle,
  expandd,
  onExpand,
  onAllocate,
  isFirstcycle,
}: Props) => {
  const { startDate, endDate, state, name } = cycle;

  const label = state === "INACTIVE" ? "Starts" : "Started:";
  const caption = isFirstcycle
    ? "Allocate & start cycle"
    : "Allocate & switch cycle";

  return (
    <CardContent
      sx={{
        "&:last-child": {
          paddingBottom: 2,
        },
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item md={2}>
          <Stack direction="row" spacing={1}>
            <Renderer value={name} fontWeight={700} />
            <Renderer value="Sparx allocation" fontWeight={700} greyed />
          </Stack>
        </Grid>
        <Grid item md={2}>
          <Stack direction="row" spacing={1}>
            <Renderer value={label} greyed />
            <Renderer
              value={formatDate(new Date(startDate))}
              greyed={state === "ACTIVE"}
            />
          </Stack>
        </Grid>
        <Grid item md={2}>
          <Stack direction="row" spacing={1}>
            <Renderer value="Ends:" greyed />
            <Renderer
              value={formatDate(new Date(endDate))}
              greyed={state !== "CLOSED"}
            />
          </Stack>
        </Grid>
        <Grid item md={6}>
          <Box display="flex" justifyContent="flex-end">
            {state === "INACTIVE" ? (
              <Button variant="contained" color="primary" onClick={onAllocate}>
                {caption}
              </Button>
            ) : (
              <CycleStatus
                state={state}
                expanded={expandd}
                onExpand={onExpand}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </CardContent>
  );
};

interface RendererProps extends TypographyProps {
  value: string;
  greyed?: boolean;
}

const Renderer = ({ value, greyed, ...rest }: RendererProps) => {
  return (
    <Typography
      {...rest}
      sx={{ color: (theme) => (greyed ? theme.palette.grey[200] : undefined) }}
    >
      {value}
    </Typography>
  );
};
