import { Chip, IconButton, Stack } from "@mui/material";
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { AllocationStatus } from "@/interface";

type Props = {
  state: AllocationStatus;
  expanded: boolean;
  onExpand: () => void;
};

export const CycleStatus = ({ expanded, state, onExpand }: Props) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {state === "ACTIVE" && <Active />}
      {state === "INACTIVE" && <Closed />}
      <IconButton size="small" onClick={onExpand}>
        {expanded ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
      </IconButton>
    </Stack>
  );
};

const Active = () => {
  return (
    <Chip
      avatar={<CircleRoundedIcon fontSize="small" sx={{ fontSize: 5 }} />}
      variant="active"
      label="Active"
      sx={{
        ".MuiChip-avatar": {
          width: 10,
          height: 10,
          color: (theme) => theme.palette.customColors.mediumGreen,
        },
      }}
    />
  );
};

const Closed = () => {
  return <Chip variant="closed" label="Closed" />;
};
