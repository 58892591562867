import {
  Transactions,
  ExtendedTransaction,
  TransactionsByCycle,
  TransactionTableCycleData,
  OwnershipFilterEnum
} from "@/interface";

export const extractTransactionsTableData = (data: TransactionsByCycle[], transactionType: 'received' | 'spent') => {
  const transactionsDetails: TransactionTableCycleData[] = [];

  data.map((cycle) => {
    const transactions = cycle.transactions.map((transaction: Transactions) => {
      const user = transactionType === "received" ? transaction.sender : transaction.receiver;
      return {
        id: transaction.id,
        amount: transaction.amount,
        type: transaction.type,
        createdAt: transaction.createdAt,
        activity: transaction.activity,
        sender: transaction.sender,
        receiver: transaction.receiver,
        displayedUser: user,
      };
    });

    transactionsDetails.push({
      symbol: cycle.symbol,
      name: cycle.name,
      endDate: cycle.endDate,
      state: cycle.state,
      transactions: transactions,
    });
  });

  return transactionsDetails;
};

export const filterTransactions = (data: TransactionTableCycleData[], filter: OwnershipFilterEnum) => {
  return data.map((item: TransactionTableCycleData) => {
    if (filter === OwnershipFilterEnum.ALL) {
      return item;
    }

    const filteredTransactions = item.transactions.filter((transaction: ExtendedTransaction) => {
      if (filter === OwnershipFilterEnum.SENT && transaction.type === "ASSIGNMENT") {
        return true;
      }
      if (filter === OwnershipFilterEnum.DELEGATED && transaction.type === "DELEGATION") {
        return true;
      }
      return false;
    });

    return { ...item, transactions: filteredTransactions };
  });
}