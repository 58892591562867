import { PaletteMode } from "@mui/material";
import { create } from "zustand";
import { ModalVariantsEnum } from "../enums/index";
import { Variant } from "@mui/material/styles/createTypography";
import { BannerSeverity } from "../components/Banner";
import React from "react";

interface UIActionsProps {
  isMenuOpen?: boolean;
  isErrorPageOpen?: boolean;
  isSnackbarOpen?: {
    variant?: "success" | "error";
    isOpen?: boolean;
    message?: React.ReactNode;
    hasFooter?: boolean;
  };
  isModalOpen?: {
    isOpen: boolean;
    content?: React.ReactNode;
    actions?: React.ReactNode;
    variant?: ModalVariantsEnum;
    title?: string;
    variantTitle?: Variant;
    footer?: React.ReactNode;
  };
  mode: PaletteMode;
  hiddenHeader?: boolean;
  hiddenBackground?: boolean;
  isBannerOpen?: {
    isOpen: boolean;
    message: React.ReactNode;
    severity: BannerSeverity;
  };
  multipleFloatingButtonsInViewport?: boolean;
}

interface UIActions extends UIActionsProps {
  setMenuOpen: (v: boolean) => void;
  setErrorPageOpen: (v: boolean) => void;
  setSnackbarOpen: ({
    variant,
    isOpen,
    message,
    hasFooter,
  }: {
    variant?: "success" | "error";
    isOpen: boolean;
    message?: React.ReactNode;
    hasFooter?: boolean;
  }) => void;
  setModalOpen: ({
    isOpen,
    content,
    actions,
    variant,
    title,
    variantTitle,
    footer,
  }: {
    isOpen: boolean;
    content: React.ReactNode;
    actions?: React.ReactNode;
    variant: ModalVariantsEnum;
    title?: string;
    variantTitle?: Variant;
    footer?: React.ReactNode;
  }) => void;
  setHiddenHeader: (v: boolean) => void;
  setHiddenBackground: (v: boolean) => void;
  setBannerOpen: ({
    isOpen,
    message,
    severity,
  }: {
    isOpen: boolean;
    message: React.ReactNode;
    severity: BannerSeverity;
  }) => void;
  setMultipleFloatingButtonsInViewport: (v: boolean) => void;
}

const useUIActionsState = create<UIActions>((set) => ({
  isMenuOpen: false,
  setMenuOpen: (v: boolean) => set({ isMenuOpen: v }),
  isErrorPageOpen: false,
  setErrorPageOpen: (v: boolean) => set({ isErrorPageOpen: v }),
  isSnackbarOpen: {
    variant: undefined,
    isOpen: false,
    message: "",
    hasFooter: false,
  },
  setSnackbarOpen: (arg) => set({ isSnackbarOpen: arg }),
  isModalOpen: {
    isOpen: false,
    content: null,
    actions: null,
    variant: ModalVariantsEnum.PRIMARY,
  },
  setModalOpen: (args) => set({ isModalOpen: args }),
  mode: "light",
  hiddenHeader: false,
  setHiddenHeader: (v: boolean) => set({ hiddenHeader: v }),
  hiddenBackground: false,
  setHiddenBackground: (v: boolean) => set({ hiddenBackground: v }),
  isBannerOpen: {
    isOpen: false,
    message: "",
    severity: BannerSeverity.INFO
  },
  setBannerOpen: (args) => set({ isBannerOpen: args }),
  multipleFloatingButtonsInViewport: false,
  setMultipleFloatingButtonsInViewport: (v: boolean) => set({ multipleFloatingButtonsInViewport: v }),
}));

export { useUIActionsState };
