import { create } from "zustand";
import { Contributor, SparxBalance } from "@/interface";
import { updateBalance } from "@/utils";

interface BulkSparxState {
  sparx?: SparxBalance;
  balance: number;
  contributors: Contributor[];
  contributor?: Contributor;
  // bulk distribution
  openDistributeModal: boolean;
  distributedAmount?: string | number;
  distributedBalance: number;
  distributedError?: string;
  totalDistributedAmount: number;
  selectedContributors: Contributor[];

  setBulkBalance: (sparx: SparxBalance) => void;
  setState: (args: Partial<BulkSparxState>) => void;
  setAmount: (id: string, amount: number | string) => void;
  setDistributedAmount: (amount: number | string) => void;
  setOpenDistributeModal: () => void;
  assignSparx: () => void;
  resetDistributed: () => void;
  reset: () => void;
}
const useBulkSparxState = create<BulkSparxState>((set, get) => ({
  balance: 0,
  contributors: [],
  openDistributeModal: false,
  selectedContributors: [],
  totalDistributedAmount: 0,
  distributedBalance: 0,

  setState: (args?: Partial<BulkSparxState>) => {
    set({ ...args });
  },

  setBulkBalance: (sparx: SparxBalance) => {
    const balance = sparx?.balance ?? 0;
    const distributedBalance = sparx?.balance ?? 0;
    set({ sparx, balance, distributedBalance });
  },

  setDistributedAmount: (amount: number | string) => {
    const { selectedContributors, balance } = get();
    const parsedAmount = isNaN(Number(amount)) ? 0 : Number(amount);
    const totalAmount = parsedAmount * selectedContributors.length;

    const distributedBalance = balance - totalAmount;
    let error = undefined;
    if (parsedAmount > 10)
      error = "Please assign max 10 Sparx per transaction.";
    else if (distributedBalance < 0)
      error = "The quantity entered exceeds the available Sparx budget.";

    set({
      totalDistributedAmount: totalAmount,
      distributedAmount: amount,
      distributedBalance,
      distributedError: error,
    });
  },

  assignSparx: () => {
    const { contributors, distributedAmount, selectedContributors, sparx } =
      get();
    for (let i = 0; i < contributors.length; i++)
      if (
        selectedContributors.find(
          (contributor) => contributor.user.id === contributors[i].user.id
        )
      )
        contributors[i].amount = distributedAmount;

    // Update balance
    const balance = updateBalance(
      sparx,
      0,
      contributors.reduce(
        (prev, next) =>
          prev + (isNaN(Number(next.amount)) ? 0 : Number(next.amount)),
        0
      )
    );

    set({
      contributors,
      balance,
    });
  },

  setAmount: (userId: string, amount: number | string) => {
    const { contributors, sparx } = get();
    let { balance } = get();
    const index = contributors.findIndex(
      (contributor) => contributor.user.id === userId
    );
    if (index > -1) contributors[index].amount = amount;

    // Update balance
    balance = updateBalance(
      sparx,
      0,
      contributors.reduce(
        (prev, next) =>
          prev + (isNaN(Number(next.amount)) ? 0 : Number(next.amount)),
        0
      )
    );

    // Clean errors if balance is positive
    if (balance >= 0)
      for (let i = 0; i < contributors.length; i++)
        contributors[i].error = undefined;
    else
      contributors[index].error =
        "The quantity entered exceeds the available Sparx budget.";

    if (Number(amount) > 10)
      contributors[index].error = "Please assign max 10 Sparx per transaction.";

    set({ contributors, balance });
  },

  setOpenDistributeModal: () => {
    const { balance } = get();
    set({
      openDistributeModal: true,
      distributedBalance: balance,
    });
  },

  resetDistributed: () => {
    const { sparx } = get();
    set({
      openDistributeModal: false,
      distributedAmount: undefined,
      totalDistributedAmount: 0,
      distributedBalance: sparx?.balance,
      distributedError: undefined,
    });
  },

  reset: () => {
    const { sparx } = get();
    const balance = sparx?.balance ?? 0;
    const distributedBalance = sparx?.balance ?? 0;

    set({
      openDistributeModal: false,
      distributedAmount: undefined,
      totalDistributedAmount: 0,
      distributedBalance,
      balance,
      distributedError: undefined,
      contributor: undefined,
    });
  },
}));

export { useBulkSparxState };
