import { AppBar, Box, createTheme, Stack, ThemeProvider } from "@mui/material";
import { HeaderMenu, ProfileDropdown } from "..";
import { APP_BAR_HEIGHT } from "@/config";
import { Logo } from "./Logo";

const Header = () => {
  return (
    <ThemeProvider
      theme={(currentTheme) =>
        createTheme({ ...currentTheme, palette: { mode: "dark" } })
      }
    >
      <AppBar
        role="toolbar"
        position="fixed"
        sx={{
          border: 0,
          height: APP_BAR_HEIGHT,
          padding: (theme) => theme.spacing(1, 2),
          background: (theme) => theme.palette.background.default,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <HeaderMenu />
          </Box>

          <Logo />
          <ProfileDropdown width={36} height={36} />
        </Stack>
      </AppBar>
    </ThemeProvider>
  );
};

export { Header };
