import { Stack, Typography } from "@mui/material";
import { Owners } from "./Owners";
import { Reviewers } from "./Reviewers";
import { Sponsors } from "./Sponsors";

type Props = {
  isCreatePage?: boolean;
};

export const Team = ({ isCreatePage }: Props) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h6">Team</Typography>
      <Owners isCreatePage={isCreatePage} />
      <Reviewers />
      <Sponsors />
    </Stack>
  );
};
