import { create } from "zustand";
import { DirectionFilterEnum, OwnershipFilterEnum, TransactionTableCycleData } from "@/interface";

interface TransactionsState {
  spentTransactions: TransactionTableCycleData[];
  receivedTransactions: TransactionTableCycleData[];
  selectedDirectionFilter: DirectionFilterEnum;
  selectedOwnershipFilter: OwnershipFilterEnum;
  expandedCyclesAll: Record<string, boolean>;
  expandedCyclesSpent: Record<string, boolean>;
  expandedCyclesReceived: Record<string, boolean>;

  setState: (args: Partial<TransactionsState>) => void;
}


const useTransactionsState = create<TransactionsState>((set) => ({
  spentTransactions: [],
  receivedTransactions: [],
  selectedDirectionFilter: DirectionFilterEnum.ALL,
  selectedOwnershipFilter: OwnershipFilterEnum.ALL,
  expandedCyclesAll: {},
  expandedCyclesSpent: {},
  expandedCyclesReceived: {},

  setState: (args?: Partial<TransactionsState>) => {
    set((state) => ({ ...state, ...args }));
  },


}));


export const getInitialExpandedCycles = (cycles: TransactionTableCycleData[]) => {
  const initialExpandedCycles: { [key: string]: boolean } = {};

  cycles.forEach((cycle) => {
    if (cycle.state === "ACTIVE") {
      initialExpandedCycles[cycle.symbol] = true;
    }
  });

  return initialExpandedCycles;
};

export { useTransactionsState };