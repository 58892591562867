import useSWR from "swr";
import { API_ENDPOINTS, SHOUTS_PAGE_SIZE, fetcher } from "@/config";
import {
  ShoutoutData,
  ShoutoutPaginationData,
  ShoutoutReceived,
  ShoutoutsView,
} from "@/interface/ShoutoutData";
import { useUserState } from "@/state";
import { useEffect, useState } from "react";
import { useReactions } from "./useReactions.ts";

export const useMyShoutouts = (view: ShoutoutsView) => {
  const [page, setPage] = useState(1);
  const [reset, setReset] = useState(false);
  const { authToken } = useUserState();
  const [shoutouts, setShoutouts] = useState<ShoutoutData[]>([]);
  const { addReaction, deleteReaction } = useReactions(setShoutouts);

  const isReceived = view === ShoutoutsView.RECEIVED;

  const url = isReceived
    ? API_ENDPOINTS.me.shoutouts.received
    : API_ENDPOINTS.me.shoutouts.given;

  const { data, mutate, isLoading } = useSWR<ShoutoutPaginationData>(
    `${url}?page=${page}&size=${SHOUTS_PAGE_SIZE}`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    if (data) {
      const items = isReceived
        ? (data?.items as ShoutoutReceived[])?.map((d) => d.shoutout)
        : (data?.items as ShoutoutData[]);

      if (reset) {
        setShoutouts(items);
        setReset(false);
      } else {
        setShoutouts([...shoutouts, ...items]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const total = data?.totalItems ?? 0;

  const loadMore = () => {
    if (shoutouts.length < total) setPage((page) => page + 1);
  };

  const refresh = () => {
    setPage(1);
    setReset(true);
    mutate();
  };

  const deleteShoutout = (shoutoutId: string) => {
    setShoutouts(
      [...shoutouts].filter((shoutout) => shoutout.id !== shoutoutId)
    );
  };

  const editShoutout = (shoutout: ShoutoutData) => {
    setShoutouts(
      [...shoutouts].map((shout) => {
        if (shout.id === shoutout.id) return { ...shout, ...shoutout };
        return { ...shout };
      })
    );
  };

  return {
    shoutouts,
    total,
    isLoading,
    loadMore,
    refresh,
    deleteShoutout,
    editShoutout,
    addReaction,
    deleteReaction,
  };
};
