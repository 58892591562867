import { Box, styled } from "@mui/material";
import { Emoji } from "./Emoji";
import { ReactionType } from "@/interface";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(1),
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1),
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.grey[50]}`,

  " & > *": {
    fontSize: 30,
    padding: theme.spacing(0.5),
    cursor: "pointer",
    transition: "all .2s ease-in-out",
    ":hover": {
      transform: "scale(1.2)",
    },
  },
}));

type Props = {
  onReact: (reaction: ReactionType) => void;
};

export const Picker = ({ onReact }: Props) => {
  return (
    <StyledBox>
      <Emoji emojiType="LOVE" onClick={() => onReact(ReactionType.LOVE)} />
      <Emoji emojiType="LIKE" onClick={() => onReact(ReactionType.LIKE)} />
      <Emoji emojiType="CLAP" onClick={() => onReact(ReactionType.CLAP)} />
      <Emoji
        emojiType="WELL_DONE"
        onClick={() => onReact(ReactionType.WELL_DONE)}
      />
      <Emoji
        emojiType="APPRECIATE"
        onClick={() => onReact(ReactionType.APPRECIATE)}
      />
    </StyledBox>
  );
};
