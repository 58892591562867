import { PropsWithChildren } from "react";
import { InitiativeData } from "@/interface";
import { GroupedAvatars } from "../GroupedAvatars";
import {
  Box,
  BoxProps,
  Card,
  CardContent,
  CardProps,
  Chip,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import { toDetails } from "@/utils";
import { CategoryIcon } from "../CategoryIcon";
import { MarkdownRenderer } from "../MarkdownRenderer";

interface InitiativeProps extends CardProps {
  data: InitiativeData;
  onClick: () => void;
}

const StyledTypography = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
});

const StyledCard = styled(Card)(({ theme }) => ({
  height: 352,
  background: theme.palette.background.default,
  transition: ".2s all ease-out",
  display: "flex",
  flexDirection: "column",
  boxShadow: theme.shadows[1],
  ":hover": {
    cursor: "pointer",
    transition: "all 0.035s ease-in",
    boxShadow: theme.shadows[2],
    transform: "scale(1.01)",
  },
  padding: theme.spacing(1),
  position: "relative",
}));

const StyledChip = styled(Chip)({
  background: "linear-gradient(89.96deg, #8A75FF 91%, #4C3AB2 108.88%)",
  color: "white",
  position: "absolute",
  top: 6.15,
  right: 0,
  borderRadius: "4px 0px 0px 4px",
  width: "144px",
  height: "32px",
  transform: "rotate(2deg)",
  boxShadow: 
    "0px 0px 0px 1px rgba(14, 63, 126, 0.0627), " +
    "0px 1.5px 1.5px -0.75px rgba(42, 51, 69, 0.0627), " +
    "0px 4.5px 4.5px -2.25px rgba(42, 51, 70, 0.0627), " +
    "0px 9px 9px -4.5px rgba(42, 51, 70, 0.0627), " +
    "0px 18px 18px -9px rgba(14, 63, 126, 0.0314), " +
    "0px 36px 36px -18px rgba(14, 63, 126, 0.0627)",
}); 


const InitiativeCard = ({ data, onClick }: InitiativeProps) => {
  const theme = useTheme();
  const {
    title,
    description,
    owners,
    region,
    offices,
    subCategories,
    category,
    collaboration,
  } = toDetails(data);

  return (
    <StyledCard elevation={0} onClick={onClick}>
      {collaboration && (<StyledChip label={"Seeking contributors"}/>)}
      <CardContent sx={{height: "100%"}}>
        <Stack justifyContent="space-between" sx={{height: "100%"}}>
          <Stack spacing={2}>
            <GroupedAvatars users={owners} />
            <Box sx={{ height: 80 }}>
              <StyledTypography
                variant="h6"
                sx={{ WebkitLineClamp: 1 }}
              >
                {title}
              </StyledTypography>
              <MarkdownRenderer
                color="text.secondary"
                sx={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  WebkitLineClamp: 2,
                }}
                markdown={description}
              />
            </Box>
          </Stack>
          <Stack spacing={1.5}>
            <Renderer icon={<CategoryIcon name={category?.name ?? ""}/> } alignment="flex-start">
              <Stack>
                <Typography variant="caption" sx={{color: theme.palette.grey[300]}}>{category?.name}</Typography>
                {subCategories.length > 0 && (
                  <Typography variant="body2"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  }}
                  >
                    {subCategories.map((sub) => sub.name).join(" • ")}
                  </Typography>
                )}
              </Stack>
            </Renderer>
            {region && (
              <Renderer
                icon={<PublicRoundedIcon color="action"/>}
              >
                <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
                  {region}
                </Typography>
              </Renderer>
            )}
            {offices.length > 0 && (
              <Renderer
                icon={<CorporateFareRoundedIcon color="action"/>}
              >
                <Typography
                  variant="body2"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {offices.map((office) => office.value).join(" • ")}
                </Typography>
              </Renderer>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </StyledCard>
  );
};

type RendererProps = BoxProps &
  PropsWithChildren<{
    icon: JSX.Element;
    alignment?: "center" | "flex-start" | "flex-end" | undefined;
  }>;

const Renderer = ({ icon, children, alignment, ...boxProps }: RendererProps) => {
  return (
    <Box display="flex" alignItems={alignment ? alignment : "center"} gap={1} {...boxProps}>
      {icon}
      {children}
    </Box>
  );
};

export { InitiativeCard };
