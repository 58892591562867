import { ROUTES } from "@/config";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

type Props = {
  showButton?: boolean;
  disabled?: boolean;
};

export const DelegateSparxButton = ({ showButton = false, disabled = false }: Props) => {
  const navigate = useNavigate();
  if (!showButton) return null;

  const toDelegate = () => navigate(ROUTES.delegate_sparx);

  return (
    <Button variant="outlined" color="primary" onClick={toDelegate} disabled={disabled}>
      Delegate Sparx
    </Button>
  );
};
