import { API_ENDPOINTS, fetcher } from "@/config";
import { ShoutoutData, ShoutoutEditBodyRequest } from "@/interface";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

export const useEditShoutout = (shoutoutId: string) => {
  const { authToken } = useUserState();

  async function sendRequest(
    url: string,
    { arg }: { arg: { data: ShoutoutEditBodyRequest } }
  ) {
    const res = await fetcher<ShoutoutData>(
      url,
      {
        config: {
          method: "PATCH",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(arg.data),
        },
      },
      {
        variant: "success",
        isOpen: true,
        message: "Shoutout added with success",
        hasFooter: false,
      }
    );

    return res;
  }

  const { trigger, isMutating, error } = useSWRMutation(
    `${API_ENDPOINTS.shoutouts.root}/${shoutoutId}`,
    sendRequest
  );

  return {
    trigger,
    isMutating,
    error,
  };
};
