import { API_ENDPOINTS, fetcher } from "@/config";
import { ApiReactionResponse, ReactionType } from "@/interface";
import { useUserState } from "@/state";
import useSWRMutation from "swr/mutation";

type Args = {
  shoutoutId: string;
  userId: string;
  reactionType: ReactionType;
};

export const useAddReaction = () => {
  const { authToken } = useUserState();

  const sendRequest = async (url: string, { arg }: { arg: { data: Args } }) => {
    const res = await fetcher<ApiReactionResponse>(url, {
      config: {
        method: "POST",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(arg.data),
      },
    });

    return res;
  };

  const { trigger, isMutating } = useSWRMutation(
    API_ENDPOINTS.shoutouts.reactions,
    sendRequest
  );

  return {
    trigger,
    isMutating,
  };
};
