import { Button, Container } from "@mui/material";
import { Header } from "./Header";
import { Cycles } from "./cycle/Cycles";
import { SubnavigationBar } from "@/components";
import { ROUTES } from "@/config";
import ManageHistoryRoundedIcon from '@mui/icons-material/ManageHistoryRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { downloadZip } from "@/utils";
import { API_ENDPOINTS } from "@/config";
import { useUserState, useUIActionsState } from "@/state";
import { useCalculatePaddingTop } from "@/hooks";

const AllocationPage = () => {
  const paddingTop = useCalculatePaddingTop();
  const { authToken } = useUserState();
  const { setSnackbarOpen } = useUIActionsState();

  const onDownload = async () => {
    const url = `${API_ENDPOINTS.admin.reports}`;
    try {
      downloadZip(authToken, url);
    } catch (e) {
      setSnackbarOpen({
        variant: "error",
        isOpen: true,
        message: (e as Error).message,
        hasFooter: false,
      });
    }
  };

  return (
    <>
      <SubnavigationBar 
          breadcrumbs={[{ label: "Sparx Allocation", href: ROUTES.allocation, icon: <ManageHistoryRoundedIcon /> }]}
          actions={(<Button
            startIcon={<ArrowDownwardRoundedIcon />}
            variant="contained"
            color="primary"
            onClick={onDownload}
          >
            Reports
          </Button>)}
        />
      <Container
        sx={{ height: "100%", display: "flex", flexDirection: "column", gap: 4, pt: paddingTop }}
      >
        <Header />
        <Cycles />
      </Container>
    </>
  );
};

export { AllocationPage };
