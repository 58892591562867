import { Stack, Typography } from "@mui/material";
import { SUBNAVIGATION_BAR_HEIGHT } from "@/config";

export const Introduction = () => {
  return (
    <Stack spacing={1} sx={{ marginTop: `${SUBNAVIGATION_BAR_HEIGHT}px`}}>
      <Typography variant="h5" component="h1" color="white">
        Discover initiatives
      </Typography>
      <Typography variant="body1" color="white" sx={{ maxWidth: 600 }}>
        Explore new ways to engage with the BCG X community through non-case
        initiatives or by starting your own.
      </Typography>
    </Stack>
  );
};
