import { useInitiativeState } from "@/state";
import { UserData } from "@/interface";
import { useEffect } from "react";
import { useReviewers } from "@/hooks/api";
import { UserAutocomplete } from "@/components";

const disabled = import.meta.env.MODE !== "development";

export const Reviewers = () => {
  const { reviewers, setState, errors } = useInitiativeState();
  const { data } = useReviewers();

  useEffect(() => {
    if (data) setState({ reviewers: data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onChange = (reviewers: UserData[]) =>
    setState({ reviewers, errors: { ...errors, reviewers: undefined } });

  return (
    <UserAutocomplete
      value={reviewers}
      onChange={onChange}
      error={!!errors?.reviewers}
      errorMsg={errors?.reviewers}
      disabled={disabled}
      helperText={"Default reviewers are set"}
      label="Reviewer"
    />
  );
};
