import { useBulkSparxState } from "@/state";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { AmountField } from "../AmountField";

type Props = {
  totalContributions?: number;
  isLoading?: boolean;
};

export const Amount = ({ totalContributions, isLoading }: Props) => {
  const { contributor, setAmount } = useBulkSparxState();

  return (
    <Box display="flex" justifyContent="space-between" gap={1}>
      <Stack spacing={1}>
        <Typography variant="subtitle1">Contributions</Typography>
        <Typography variant="h6">
          {isLoading ? <Skeleton width={50} /> : totalContributions}
        </Typography>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="subtitle1" align="right">
          Sparx Amount
        </Typography>
        <AmountField
          amount={contributor?.amount}
          error={contributor?.error}
          setAmount={(value) => setAmount(contributor?.user.id ?? "", value)}
        />
      </Stack>
    </Box>
  );
};
