import { PropsWithChildren, useEffect, useState } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ClickableLinkPlugin from "@lexical/react/LexicalClickableLinkPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";

// custom plugins
import { LoadInitialContent } from "./plugins/LoadInitialContent";
import FloatingLinkEditorPlugin from "./plugins/FloatingLinkEditorPlugin";
import { MarkdownPlugin } from "./plugins/MarkdownPlugin";
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin/FloatingMenu";
import LinkPlugin from "./plugins/LinkPlugin";
// styling
import "./index.css";
// utils
import { CAN_USE_DOM } from "./utils/canUseDOM";
// ui
import ContentEditable from "./ui/ContentEditable";
import Placeholder from "./ui/Placeholder";
// config
import { initialConfig } from "./config/config";

type LexicalEditorProps = PropsWithChildren<{
  placeholder?: string;
  initialContent?: string;
  onChange: (markdown: string) => void;
}>;

export const Editor = ({
  placeholder,
  initialContent,
  onChange,
  children,
}: LexicalEditorProps) => {
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] =
    useState<boolean>(false);
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia("(max-width: 1025px)").matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener("resize", updateViewPortWidth);

    return () => {
      window.removeEventListener("resize", updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  return (
    <LexicalComposer
      initialConfig={{
        ...initialConfig,
      }}
    >
      <div className="editor-shell">
        <div className="editor-container">
          <RichTextPlugin
            contentEditable={
              <div className="editor-scroller">
                <div className="editor" ref={onRef}>
                  <ContentEditable />
                </div>
              </div>
            }
            placeholder={<Placeholder>{placeholder}</Placeholder>}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <AutoFocusPlugin />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          <ListPlugin />
          <LinkPlugin />
          <ClickableLinkPlugin disabled={true} />
          {floatingAnchorElem && !isSmallWidthViewport && (
            <>
              <FloatingLinkEditorPlugin
                anchorElem={floatingAnchorElem}
                isLinkEditMode={isLinkEditMode}
                setIsLinkEditMode={setIsLinkEditMode}
              />
              <FloatingTextFormatToolbarPlugin
                anchorElem={floatingAnchorElem}
                setIsLinkEditMode={setIsLinkEditMode}
              />
            </>
          )}
          <MarkdownPlugin onMarkdownChanged={onChange} />
          <LoadInitialContent initialContent={initialContent} />
          {children}
        </div>
      </div>
    </LexicalComposer>
  );
};
