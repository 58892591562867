import { Breadcrumbs, Box, IconButton, Link, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { APP_BAR_HEIGHT, SUBNAVIGATION_BAR_HEIGHT } from "@/config";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate } from 'react-router-dom';

type Props = {
  breadcrumbs: { label: string; href?: string; onClick?: () => void; icon?: JSX.Element }[];
  tabs?: JSX.Element;
  actions?: JSX.Element;
  statusChips?: JSX.Element;
  showLastBreadcrumbOnly?: boolean;
};

const BreadcrumbsSection = ({ breadcrumbs, smallerScreens }: { breadcrumbs: Props['breadcrumbs'], smallerScreens: boolean }) => {
  const navigate = useNavigate();
  const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {smallerScreens ? (
        <Link
          underline="hover"
          color="textPrimary"
          onClick={() => navigate(-1)}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <IconButton sx={{ padding: 0 }}>
            <ArrowBackRoundedIcon />
          </IconButton>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {lastBreadcrumb.label}
          </Typography>
        </Link>
      ) : (
        breadcrumbs.map((crumb, index) => (
          <Link
            key={index}
            underline="hover"
            color={index === breadcrumbs.length - 1 ? 'textPrimary' : 'inherit'}
            href={!crumb.onClick ? crumb.href : undefined}
            onClick={crumb.onClick}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {crumb.icon && <Box sx={{ mr: 1 }}>{crumb.icon}</Box>}
            <Typography variant="subtitle1">{crumb.label}</Typography>
          </Link>
        ))
      )}
    </Breadcrumbs>
  );
};

const SubnavigationBar = ({ breadcrumbs, tabs, actions, statusChips, showLastBreadcrumbOnly = false }: Props) => {
  const theme = useTheme();
  const smallerScreens = useMediaQuery(theme.breakpoints.down("md"));

  const commonStyles = {
    display: "flex",
    alignItems: "center",
    padding: "8px 32px",
    borderBottom: "1px solid #e0e0e0",
    position: "absolute",
    left: 0,
    width: "100%",
    height: `${SUBNAVIGATION_BAR_HEIGHT}px`,
    boxShadow: theme.shadows[1],
  };

  return (
    <Box sx={{ position: "relative" }}>
      {smallerScreens ? (
        <>
          <Box sx={{ ...commonStyles, justifyContent: "space-between", top: `${APP_BAR_HEIGHT}px` }}>
            <Stack direction="row" gap={1} alignItems="center">
              <BreadcrumbsSection breadcrumbs={breadcrumbs} smallerScreens={smallerScreens}/>
              {statusChips}
            </Stack>
            {actions}
          </Box>
          {tabs && (
            <Box sx={{ ...commonStyles, justifyContent: "center", top: `${APP_BAR_HEIGHT + SUBNAVIGATION_BAR_HEIGHT}px` }}>
              {tabs}
            </Box>
          )}

        </>
      ) : (
        <Box sx={{ ...commonStyles, justifyContent: "space-between", top: 0 }}>
          <Stack direction="row" gap={1} alignItems="center">
            <BreadcrumbsSection breadcrumbs={breadcrumbs} smallerScreens={smallerScreens || showLastBreadcrumbOnly}/>
            {statusChips}
          </Stack>
          {tabs}
          <div>
            {actions}
          </div>
        </Box>
      )}
    </Box>
  );
};

export { SubnavigationBar };