import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { RowItem } from "./RowItem";
import { useUserState } from "@/state";
import { NoDataMessage } from "@/components";
import { ROUTES } from "@/config";
import { EnrollmentData } from "@/interface";

type OrderByOptions = "name" | "category" | "role" | "status" | undefined;

type Props = {
  enrollments: EnrollmentData[];
}

export const JoinedInitiativesTable = ({enrollments}: Props) => {
  const navigate = useNavigate();
  const { userData } = useUserState();
  const [orderBy, setOrderBy] = useState<OrderByOptions>(undefined);
  const [order, setOrder] = useState<"asc" | "desc">("desc");

  const handleRequestSort = (property: OrderByOptions) => {
    if (orderBy === property) {
      if (order === "asc") {
        setOrder("desc");
      } else if (order === "desc") {
        setOrderBy(undefined);
        setOrder("desc");
      } else {
        setOrder("asc");
      }
    } else {
      setOrderBy(property);
      setOrder("asc");
    }
  };

  const sortedEnrollments: EnrollmentData[] = useMemo(() => {
    return enrollments.slice().sort((a, b) => {
      if (!orderBy) return 0;

      let aValue: number | string;
      let bValue: number | string;

      switch (orderBy) {
        case "name":
          aValue = a.initiative?.title;
          bValue = b.initiative?.title;
          break;
        case "category":
          aValue = a.initiative.category?.name ?? 0;
          bValue = b.initiative.category?.name ?? 0;
          break;
        case "role": {
          const aHasRole = a.initiative.owners?.find((owner) => owner.userId === userData?.id) ?? false;
          const bHasRole = b.initiative.owners?.find((owner) => owner.userId === userData?.id) ?? false;
          // Convert boolean to number for sorting, true becomes 1 and false becomes 0
          aValue = aHasRole ? 1 : 0;
          bValue = bHasRole ? 1 : 0;
          break;
        }
        case "status":
          aValue = a.initiative.status;
          bValue = b.initiative.status;
          break;
        default:
          aValue = a.updatedAt ?? 0;
          bValue = b.updatedAt ?? 0;
          break;
      }

      if (aValue < bValue) {
        return order === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [enrollments, orderBy, userData?.id, order]);

  return (
    <TableContainer sx={{ py: 2 }}>
      <Table>
        <TableHead>
          <TableRow
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.divider}`,
            })}
          >
            <TableCell>
              <TableSortLabel
                active={orderBy === "name"}
                direction={orderBy === "name" ? order : "asc"}
                onClick={() => handleRequestSort("name")}
              >
                <Typography variant="subtitle2">Initiative name</Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "category"}
                direction={orderBy === "category" ? order : "asc"}
                onClick={() => handleRequestSort("category")}
              >
                <Typography variant="subtitle2">Category</Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "role"}
                direction={orderBy === "role" ? order : "asc"}
                onClick={() => handleRequestSort("role")}
              >
                <Typography variant="subtitle2">Role</Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "status"}
                direction={orderBy === "status" ? order : "asc"}
                onClick={() => handleRequestSort("status")}
              >
                <Typography variant="subtitle2">Initiative status</Typography>
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedEnrollments?.length === 0 ? (
            <NoDataMessage
              colSpan={4}
              navigate={navigate}
              title={"You have not joined or created any initiatives yet."}
              message={
                "Do you have an idea for an initiative but you haven't started yet? Create one here and start connecting with your colleagues."
              }
              buttonText="Browse initiatives"
              route={ROUTES.initiatives}
            />
          ) : (
            sortedEnrollments?.map((enrollment) => <RowItem key={enrollment.id} enrollment={enrollment} />)
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
