import { InitiativeOwnershipData } from "@/interface";
import { useUIActionsState, useUserState } from "@/state";
import { PropsWithChildren } from "react";
import { ROLES as ROLE } from "@/interface";

type Props = PropsWithChildren<{
  role: ROLE;
  owners: InitiativeOwnershipData[];
}>;

export const InitiativeGuard = ({ role, owners, children }: Props) => {
  const { userData } = useUserState();
  const ownerAccess = owners.find((owner) => owner.userId === userData?.id);
  const roleAccess = userData?.roles?.includes(role);
  const canAccess = ownerAccess || roleAccess;

  const { setErrorPageOpen } = useUIActionsState();

  if (canAccess) return children;

  setErrorPageOpen(true);
};
