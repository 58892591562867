import { useMemo } from "react";
import { Stack } from "@mui/material";
import { DirectionFilterEnum, TransactionTableCycleData } from "@/interface";
import { useTransactionsState } from "@/state";
import { EmptyTransactionsCard } from "../common/EmptyTransactionsCard";
import { TransactionsTable } from "../common/TransactionsTable";
import {filterTransactions} from "@/utils";

type Props = {
  mutate: () => void
} 

export const ReceivedTransactions = ({ mutate }: Props) => {
  const { receivedTransactions, selectedOwnershipFilter } = useTransactionsState();

  const filteredTransactions: TransactionTableCycleData[] = useMemo(() => { 
    return filterTransactions(receivedTransactions, selectedOwnershipFilter);
  }, [receivedTransactions, selectedOwnershipFilter]);
  
  if (!filteredTransactions || filteredTransactions?.length === 0) return <EmptyTransactionsCard type={DirectionFilterEnum.RECEIVED} />;

  return (
    <Stack pt={2}>
      <TransactionsTable cycleTransactions={filteredTransactions} transactionsType={DirectionFilterEnum.RECEIVED} mutate={mutate}/>
    </Stack>
  );
};