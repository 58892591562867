export type MenuItem = {
  icon?: JSX.Element;
  label: string;
  to?: string;
};

export enum ROLES {
  USER = "USER",
  ADMIN = "ADMIN",
  TOKEN_MANAGER = "TOKEN_MANAGER",
}

export type LinkVO = {
  url: string;
  label: string;
};

export type InitiativeOffice = {
  id: string;
  label: string;
  value: string;
  region?: string;
};

export type SelectOptionsProps = {
  value: string | number;
  label: string;
};

export type SentSparx = {
  receiverId: string;
  senderId: string;
  senderIsWhale: boolean;
  amount: number;
};

export type KeyValuePair = {
  key: string;
  value: string;
};

export type FilterOption = KeyValuePair & {
  children?: KeyValuePair[];
};
