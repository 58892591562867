import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import Image from "@/assets/EmptyState.png";
import { DirectionFilterEnum } from "@/interface";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/config";
import { useSparxState, useUserState } from "@/state";

type Props = {
  type: DirectionFilterEnum;
};

const data: Record<
  DirectionFilterEnum,
  { title: string; subTitle: string; button: string }
> = {
  [DirectionFilterEnum.SPENT]: {
    title: "You haven’t spent any Sparx yet",
    subTitle: "All Sparx that you send will be shown here",
    button: "Send Sparx",
  },
  [DirectionFilterEnum.RECEIVED]: {
    title: "You haven’t received any Sparx yet",
    subTitle: "All Sparx delegated from other users will be shown here",
    button: "Delegate Sparx",
  },
  [DirectionFilterEnum.ALL]: {
    title: "You haven’t spent or received any Sparx yet",
    subTitle: "All spent and received Sparx will be shown here",
    button: "Send Sparx",
  }
};

export const EmptyTransactionsCard = ({ type }: Props) => {
  const { sparx } = useSparxState();
  const navigate = useNavigate();
  const { userData } = useUserState();
  const isWhale = userData?.isWhale;

  const onClick = () =>
    type === DirectionFilterEnum.RECEIVED
      ? navigate(ROUTES.delegate_sparx)
      : navigate(ROUTES.send_sparx);

      const showButton = type === DirectionFilterEnum.RECEIVED ? isWhale : true;
  return (
    <Stack spacing={{ xs: 2, md: 3 }}>
      <Card
        elevation={0}
        sx={{
          height: 272,
          width: "100%",
          backgroundImage: `url(${Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          border: 0,
        }}
      >
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height={272}
          >
            <Typography variant="h6" component="h1" gutterBottom>
              {data[type].title}
            </Typography>
            <Typography color="body2">{data[type].subTitle}</Typography>
            {showButton && <Box mt={2}>
              <Button
                variant="text"
                color="primary"
                disabled={sparx?.balance === 0}
                endIcon={<SendRoundedIcon />}
                onClick={onClick}
              >
                {data[type].button}
              </Button>
            </Box>}
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};
