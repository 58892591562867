import { useMediaQuery, useTheme } from "@mui/material";

import { APP_BAR_HEIGHT, SUBNAVIGATION_BAR_HEIGHT } from "@/config";

export const useCalculatePaddingTop = () => {
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const paddingTop = isMediumUp ? `${32 + SUBNAVIGATION_BAR_HEIGHT}px` : `${APP_BAR_HEIGHT + 2 * SUBNAVIGATION_BAR_HEIGHT}px`;
  
  return paddingTop
};
