import { useState } from "react";
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack
} from "@mui/material";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { MessageToggle } from "@/pages/common/sparx/MessageToggle";
import { LeaveMessage } from "@/pages/common/sparx/LeaveMessage";
import { useBulkSparxState, useSparxState, useShoutsState, useUserState } from "@/state";
import { Balance } from "@/components";
import { DistributionContent } from "./DistributionContent";
import { useSendTokens, useShoutout } from "@/hooks/api";
import { toActivityBodyRequest, toShoutout } from "@/utils";

type Props = {
  onSuccess: () => void;
};

export const DistributionModal = ({ onSuccess }: Props) => {
  const [showMessageComponent, setShowMessageComponent] = useState<boolean>(false);
  const { userData } = useUserState();
  const { activity, shoutout, isPublic, setReset } = useSparxState();
  const {
    openDistributeModal,
    resetDistributed,
    sparx,
    distributedBalance,
    totalDistributedAmount,
    distributedError,
    reset
  } = useBulkSparxState();
  const { setState: setShoutoutState } = useShoutsState();
  const { trigger } = useSendTokens();
  const { trigger: triggerShoutouts } = useShoutout();

  const onClose = () => {
    resetDistributed()
    setShowMessageComponent(false);
  };

  const onSendSparx = () => {
    if (activity && userData) {
      trigger({ data: toActivityBodyRequest(activity, userData) }).then(() => {
        setReset();
        reset();
        onSuccess();
      })

      if (shoutout)
        triggerShoutouts({ data: toShoutout(activity, isPublic) }).then(
          (shout) => {
            setShoutoutState({ shoutout: shout });
          }
        );
    }

  };

  return (
    <Dialog
      open={openDistributeModal}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            pt: 2,
          },
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "white",
        },
      }}
    >
      <DialogTitle textAlign="center">Send Sparx</DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="center" mb={2}>
          Please choose the Sparx amount you wish to award for this contribution. 
          Optionally, you can also leave a message for the recipient.
        </DialogContentText>
        <DistributionContent />
        <Stack spacing={2} pt={2}>
          <MessageToggle
            toggeled={showMessageComponent}
            onClick={() => setShowMessageComponent(!showMessageComponent)}
            disableRipple
          />
          <Collapse in={showMessageComponent}>
            <LeaveMessage />
          </Collapse>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ display: "flex" }}>
        <Balance
          balance={distributedBalance}
          sparx={sparx}
          sx={{ mr: "auto" }}
        />
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<SendRoundedIcon />}
          onClick={onSendSparx}
          disabled={totalDistributedAmount <= 0 || !!distributedError}
        >
          Send Sparx
        </Button>
      </DialogActions>
    </Dialog>
  );
};
