import { PropsWithChildren } from "react";
import { Drawer as MuiDrawer } from "@mui/material";
import {
  CSSObject,
  Theme,
  ThemeProvider,
  createTheme,
  styled,
} from "@mui/material/styles";

type MenuProps = PropsWithChildren<{
  open: boolean;
}>;

const WIDTH = 240;
const MINI_WIDTH = 72;

const openedMixin = (theme: Theme): CSSObject => ({
  width: WIDTH,
  padding: theme.spacing(2),
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  background: theme.palette.background.default,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  padding: theme.spacing(2),
  width: MINI_WIDTH,
  background: theme.palette.background.default,
});

const StyleDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const Drawer = ({ open, children }: MenuProps) => {

  return (

    <ThemeProvider
      theme={(currentTheme) => createTheme({ ...currentTheme, palette: { mode: "dark" } })}
    >
      <StyleDrawer variant="permanent" open={open}>
        {children}
      </StyleDrawer>
    </ThemeProvider>
  );
};
