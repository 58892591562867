import { Typography, Button, Stack, DialogActions } from "@mui/material";
import { SparxDialog } from "./SparxDialog";
import { useDeleteTransaction } from "@/hooks/api";
import { useSparxState } from "@/state";
import { LoadingButton } from "@mui/lab";
import { ReplyRounded } from "@mui/icons-material";
import { API_ENDPOINTS } from "@/config";
import { useSWRConfig } from "swr";

type Props = {
  onClose: () => void;
  sparxAmount?: number;
};

export const DeleteDialog = ({ onClose, sparxAmount }: Props) => {
  const { activity, transactionId, setReset } = useSparxState();
  const { trigger, isDeleting } = useDeleteTransaction(transactionId ?? "");
  const { mutate } = useSWRConfig();

  const onDelete = () => {
    trigger().then(() => {
      onClose();
      setReset();
      mutate(API_ENDPOINTS.tokens.balance);
    });
  };

  const amount = sparxAmount ? sparxAmount :
    activity?.transactions?.find((tr) => tr.id === transactionId)?.amount ?? 0;

  return (
    <SparxDialog
      title="Revert Transaction?"
      subTitle="Are you sure you want to revert this transaction? This action is permanent."
      onClose={onClose}
    >
      <Stack spacing={3}>
        <Typography variant="body1" textAlign="left" color="text.primary">
          {amount} Sparx will be credited back to your budget.
        </Typography>
        <DialogActions sx={{ p: 0 }}>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            startIcon={<ReplyRounded />}
            variant="contained"
            color="error"
            loading={isDeleting}
            onClick={onDelete}
          >
            Revert Transaction
          </LoadingButton>
        </DialogActions>
      </Stack>
    </SparxDialog>
  );
};
