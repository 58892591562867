import { useState, useMemo } from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { DirectionFilterEnum, TransactionTableCycleData, ExtendedTransaction } from "@/interface";
import { CollapsibleCycleSection } from "./CollapsibleCycleSection";
import AutoAwesomeRounded from "@mui/icons-material/AutoAwesomeRounded";

type Props = {
  cycleTransactions: TransactionTableCycleData[];
  transactionsType: DirectionFilterEnum;  
  mutate: (() => void) | undefined;
};

const sortTransactions = (transactions: ExtendedTransaction[], orderBy: string, order: string) => {
  return transactions.sort((a, b) => {
    const getSortingName = (transaction: ExtendedTransaction) => {
      return transaction.displayedUser?.lastName || "";
    };

    if (orderBy === "user") {
      return (getSortingName(a) < getSortingName(b) ? -1 : 1) * (order === "asc" ? 1 : -1);
    } else if (orderBy === "sparx") {
      return (a.amount - b.amount) * (order === "asc" ? 1 : -1);
    } else if (orderBy === "date") {
      return (new Date(a.createdAt) < new Date(b.createdAt) ? -1 : 1) * (order === "asc" ? 1 : -1);
    }
    return 0;
  });
};

export const TransactionsTable = ({
  cycleTransactions = [],
  transactionsType,
  mutate
}: Props) => {
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("date");

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedCycleTransactions = useMemo(() => {
    return cycleTransactions
      .sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime())
      .map(cycle => ({
        ...cycle,
        transactions: sortTransactions(cycle.transactions, orderBy, order)
      }));
  }, [cycleTransactions, orderBy, order]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.divider}`,
            })}
          >
            <TableCell sx={{ width: "10%" }} />
            <TableCell sx={{ width: "30%" }}>
              <TableSortLabel
                active={orderBy === "user"}
                direction={orderBy === "user" ? order : "asc"}
                onClick={() => handleRequestSort("user")}
              >
                <Typography variant="subtitle2">User</Typography>
              </TableSortLabel>
            </TableCell >
            <TableCell align="right" sx={{ width: "25%" }}>
              <TableSortLabel
                active={orderBy === "sparx"}
                direction={orderBy === "sparx" ? order : "asc"}
                onClick={() => handleRequestSort("sparx")}
              >
                <Typography variant="subtitle2">Sparx</Typography>
                <AutoAwesomeRounded sx={{ color: (theme) => `${theme.palette.primary.dark}`, mr: 1 }}/>
              </TableSortLabel>
            </TableCell>
            <TableCell align="right" sx={{ width: "25%" }}>
              <TableSortLabel
                active={orderBy === "date"}
                direction={orderBy === "date" ? order : "asc"}
                onClick={() => handleRequestSort("date")}
              >
                <Typography variant="subtitle2">Date</Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "10%" }}/>
          </TableRow>
        </TableHead>
        <CollapsibleCycleSection cycleTransactions={sortedCycleTransactions} transactionsType={transactionsType} mutate={mutate}/>
      </Table>
    </TableContainer>
  );
};