import { Typography, Button } from "@mui/material";
import { SparxDialog } from "./SparxDialog";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/config";
import { useSparxState } from "@/state";
import { endCycleDateFormat } from "@/utils";

type Props = {
  title?: string;
  subTitle?: string;
  onClose: () => void;
};

export const SuccessDialog = ({ title, subTitle, onClose }: Props) => {
  const navigate = useNavigate();
  const toSparx = () => navigate(ROUTES.sparx);
  const { sparx } = useSparxState();

  return (
    <SparxDialog
      title={title || "Your Sparx are sent!"}
      subTitle={
        subTitle ||
        `You can edit or revert your transaction until ${endCycleDateFormat(
          new Date(sparx?.cycle.endDate ?? "")
        )}`
      }
      onClose={onClose}
    >
      <Button variant="contained" color="secondary" onClick={toSparx}>
        Got it
      </Button>
      <Typography variant="subtitle2" textAlign="center">
        Any unused Sparx will reset at the end of this quarter.
      </Typography>
    </SparxDialog>
  );
};
