import { Box, Typography, alpha, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { AmountField } from "../AmountField";
import { useBulkSparxState, useSparxState } from "@/state";
import { GroupedAvatars } from "@/components";
import { UserData } from "@/interface";
import { AutoAwesomeRounded } from "@mui/icons-material";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  border: `1px solid ${grey[300]}`,
  borderRadius: theme.spacing(1),
  overflow: "hidden",
  ".item": {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
    "&  > *": { flex: 1 },
  },
  ".border-bottom": {
    borderBottom: `1px solid ${grey[300]}`,
  },
  ".error": {
    background: alpha(theme.palette.error.light, 0.1),
  },
}));

export const DistributionContent = () => {
  const { setBulkAmount } = useSparxState();
  const {
    totalDistributedAmount,
    selectedContributors,
    distributedAmount,
    distributedError,
    setDistributedAmount,
  } = useBulkSparxState();

  const users = selectedContributors.map((c) => c.user) as UserData[];

  return (
    <StyledBox>
      <Box className="item border-bottom">
        <Typography variant="subtitle2">Contributors</Typography>
        <Typography variant="subtitle2" align="right">
          Sparx per Person
        </Typography>
      </Box>
      <Box className="item border-bottom">
        <Box>
          <GroupedAvatars users={users} />
        </Box>
        <Box display="flex" flexDirection="column">
          <AmountField
            amount={distributedAmount}
            setAmount={(distributedAmount) => {
              setDistributedAmount(distributedAmount)
              setBulkAmount(distributedAmount)
            }}
          />
          {distributedError && (
            <Typography variant="subtitle2" color="error" textAlign="right">
              {distributedError}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        className={`item ${distributedError ? "error" : ""}`}
        sx={{
          background: (theme) => theme.palette.background.paper,
          height: 40,
        }}
      >
        <Box display="flex" alignItems="center">
          <Typography>
            People <strong>{users.length}</strong>&nbsp; x
          </Typography>
          <AutoAwesomeRounded
            color="secondary"
            sx={{ fontSize: 14, mx: 0.5 }}
          />
          <Typography color="secondary">
            <strong>{distributedAmount ?? 0}</strong>
          </Typography>
        </Box>
        <Typography align="right">
          Total Sparxs: <strong>{totalDistributedAmount}</strong>
        </Typography>
      </Box>
    </StyledBox>
  );
};
