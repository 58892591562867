import { Box, CardContent, Stack, Typography } from "@mui/material";
import shoutout from "@/assets/reward/Shoutout.svg";
import { Reward } from "@/interface";
import { TaggedUserList, UserAvatar, UserInfo } from "@/components";
import { formatDateLongMonth } from "@/utils";
import { Crosspost } from "./Crosspost";
import { SlackDialog } from "./SlackDialog";
import { useRewardsState } from "@/state";

type Props = {
  reward: Reward;
};

export const Shoutout = ({ reward }: Props) => {
  const { openSlackDialog, setState } = useRewardsState();
  const { author, createdAt, receivers, details } = reward;

  const handleOutlookClick = () => {
    window.location.href = `mailto:${author?.email || ""}?subject=Thanks%20for%20the%20shout-out!`;
  };

  return (
    <>
      <Box className="media">
        <img src={shoutout} />
      </Box>

      <CardContent sx={{ px: 2, width: 412 }}>
        <Stack spacing={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <UserAvatar user={author}>
              <Stack>
                <UserInfo user={author} />
                <Typography variant="caption" color="text.secondary">
                  Posted a shoutout
                </Typography>
              </Stack>
            </UserAvatar>
            {createdAt && (
              <Typography variant="body2" color="text.secondary">
                {formatDateLongMonth(new Date(createdAt))}
              </Typography>
            )}
          </Stack>
          <TaggedUserList receivers={receivers ?? []} />
          <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
            “{details}”
          </Typography>
          <Crosspost onClickOutlook={handleOutlookClick}/>
        </Stack>
      </CardContent>

      {openSlackDialog && <SlackDialog open={openSlackDialog} onCancel={() => setState({ openSlackDialog: false})} author={author}/>}
    </>
  );
};
