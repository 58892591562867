import { useEffect, useState } from "react";
import { Box, Collapse, Stack } from "@mui/material";
import { SaveButton } from "../common/SaveButton";
import { useSparxState } from "@/state";
import { MessageToggle } from "@/pages/common/sparx/MessageToggle";
import { LeaveMessage } from "@/pages/common/sparx/LeaveMessage";

type Props = {
  setOpenDialog: (value: boolean) => void;
  allowPostToShoutouts?: boolean;
};

export const SparxMessageWithToggle = ({
  setOpenDialog,
  allowPostToShoutouts = true,
}: Props) => {
  const [showMessageComponent, setShowMessageComponent] = useState<boolean>(false);
  const { activity } = useSparxState();

  useEffect(() => {
    if (!activity || activity?.transactions.length === 0) {
      //Reset message if activity or transactions have been removed
      setShowMessageComponent(false);
    }
  }, [activity]);

  if (!activity || activity?.transactions.length === 0) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <MessageToggle
        toggeled={showMessageComponent}
        onClick={() => setShowMessageComponent(!showMessageComponent)}
        disableRipple
      />

      <Collapse in={showMessageComponent}>
        <LeaveMessage
          resetMessage={!showMessageComponent}
          allowPostToShoutouts={allowPostToShoutouts}
        />
      </Collapse>
      <Box display="flex" justifyContent="flex-end">
        <SaveButton title="Confirm & Save" onDone={() => setOpenDialog(true)} />
      </Box>
    </Stack>
  );
};
