import { EnrollmentStatusEnum, InitiativeStatus } from "@/enums";
import {
  Category,
  CategoryData,
  CommitmentEnum,
  EnrollmentData,
  InitiativeBase,
  InitiativeData,
  InitiativeOffice,
  InitiativeOverview,
  InitiativeOwnershipData,
  ParticipationEnum,
  UserData,
  ValidationResult,
} from "@/interface";

export const toOwners = (owners?: InitiativeOwnershipData[]) => {
  if (!owners) return null;
  return owners
    .map(({ user }) => `${user.firstName} ${user.lastName}`)
    .join(", ");
};

export const toServer = (base: InitiativeBase) => {
  const {
    title,
    description,
    categoryId,
    subCategories,
    offices,
    startDate,
    endDate,
    region,
    collaboration,
    links,
    owners,
    reviewers,
  } = base;
  return {
    fields: {
      title,
      details: description,
      categoryId,
      subCategoryIds: subCategories.map((cat) => cat.id),
      offices: offices.map((office) => office.id),
      startDate,
      endDate,
      region,
      collaboration,
      participation: mapParticipationEnumToServerValue(base.participation),
      commitment: mapCommitmentEnumToServerValue(base.commitment),
      links,
      owners,
      reviewers,
      mdps: base.sponsors ?? [],
    },
  };
};

export const initInitiativeState = (): InitiativeBase => {
  return {
    title: "",
    description: "",
    categoryId: "",
    category: undefined,
    subCategories: [],
    location: [],
    offices: [],
    region: "",
    collaboration: false,
    commitment: CommitmentEnum.LOW,
    participation: ParticipationEnum.REMOTE,
    owners: [],
    reviewers: [],
    links: [],
    startDate: new Date().toISOString(),
  };
};

export const mapResponseCommitment = (value: string): CommitmentEnum => {
  switch (value.toUpperCase()) {
    case "LOW":
      return CommitmentEnum.LOW;
    case "MEDIUM":
      return CommitmentEnum.MEDIUM;
    case "HIGH":
      return CommitmentEnum.HIGH;
    default:
      return CommitmentEnum.LOW;
  }
};

export const mapCommitmentEnumToServerValue = (
  enumValue: CommitmentEnum
): string => {
  switch (enumValue) {
    case CommitmentEnum.LOW:
      return "LOW";
    case CommitmentEnum.MEDIUM:
      return "MEDIUM";
    case CommitmentEnum.HIGH:
      return "HIGH";
  }
};

export const mapResponseToParticipation = (
  value: string
): ParticipationEnum => {
  switch (value.toUpperCase()) {
    case "REMOTE":
      return ParticipationEnum.REMOTE;
    case "HYBRID":
      return ParticipationEnum.HYBRID;
    case "IN_PERSON":
      return ParticipationEnum.IN_PERSON;
    default:
      return ParticipationEnum.REMOTE;
  }
};

export const mapParticipationEnumToServerValue = (
  enumValue: ParticipationEnum
): string => {
  switch (enumValue) {
    case ParticipationEnum.REMOTE:
      return "REMOTE";
    case ParticipationEnum.HYBRID:
      return "HYBRID";
    case ParticipationEnum.IN_PERSON:
      return "IN_PERSON";
  }
};

export const toLocal = (response: InitiativeData): InitiativeBase => {
  const {
    title,
    details,
    region,
    categoryId,
    links,
    owners,
    reviewers,
    mdps,
    startDate,
    endDate,
    offices,
    collaboration,
    commitment,
    participation,
    initiativeCategories,
    category,
  } = response;

  const parseUser = (users?: InitiativeOwnershipData[]) => {
    if (users?.length === 0) return undefined;
    return users?.map((r) => r.user) as UserData[];
  };

  const toOffice = (): InitiativeOffice[] => {
    const arr: InitiativeOffice[] = [];

    offices?.forEach((office) => {
      arr.push({
        id: office.office?.id ?? "",
        value: office.office?.name ?? "",
        label: office.office?.name ?? "",
        region,
      });
    });

    return arr;
  };

  const toSubCategories = () => {
    const arr: Category[] = [];
    initiativeCategories.forEach((category) => {
      arr.push({
        id: category.categoryId,
        name: category.category.name,
        description: "",
        subCategories: [],
      });
    });

    return arr;
  };

  const initiative: InitiativeBase = {
    ...initInitiativeState(),
    title,
    description: details,
    links: links?.map((link) => link) ?? [],
    reviewers: parseUser(reviewers) ?? [],
    owners: parseUser(owners) ?? [],
    sponsors: parseUser(mdps),
    startDate: startDate,
    endDate,
    region: region ?? "",
    categoryId: categoryId ?? "",
    category,
    subCategories: toSubCategories(),
    offices: toOffice(),
    collaboration,
    commitment: mapResponseCommitment(commitment),
    participation: mapResponseToParticipation(participation),
  };

  return initiative;
};

export const toDetails = (data: InitiativeData | undefined) => {
  const local = data ? toLocal(data) : initInitiativeState();

  const {
    title,
    description,
    region,
    offices,
    category,
    categoryId,
    subCategories,
    owners,
    sponsors,
    collaboration,
    participation,
    commitment,
    startDate,
    endDate,
    links,
  } = local;

  const getParticipants = () => {
    const participants: UserData[] = [];

    const isOwner = (user: UserData) =>
      owners.find((owner) => owner?.id === user?.id);

    data?.enrollment?.forEach((enrollment) => {
      const { status, user } = enrollment;
      if (!isOwner(user) && status === EnrollmentStatusEnum.ENROLLED)
        participants.push(user);
    });

    return participants;
  };

  const mapStatusToEnum = (status: string) => {
    if (status in InitiativeStatus) {
      return InitiativeStatus[status as keyof typeof InitiativeStatus];
    }
    return undefined;
  };

  return {
    id: data?.id,
    title,
    description,
    region,
    offices,
    categoryId,
    category,
    subCategories,
    owners,
    sponsors: sponsors ?? [],
    collaboration,
    participation,
    commitment,
    startDate,
    endDate,
    enrollments: data?.enrollment ?? [],
    participants: getParticipants(),
    status: mapStatusToEnum(data?.status ?? ""),
    reviewNote: data?.reviewNote,
    links,
  };
};

export const getEnrollments = (
  enrollments: EnrollmentData[],
  user?: UserData
) => {
  let enrolled = undefined;
  let dropped = undefined;

  for (const en of enrollments) {
    if (en.user.id === user?.id) {
      if (en.status === EnrollmentStatusEnum.ENROLLED) {
        enrolled = en;
      } else if (en.status === EnrollmentStatusEnum.DROPPED) {
        dropped = en;
      }
      if (enrolled && dropped) {
        break;
      }
    }
  }

  return { enrolled, dropped };
};

export const getInitiativesToReview = (
  reviewerInitiatives: InitiativeData[],
  ownerInitiatives: InitiativeData[]
) => {
  const initiativesAwaitingReview =
    reviewerInitiatives?.filter(
      (initiative) => initiative.status === "SUBMITTED"
    ) ?? [];
  const initiativesRejected =
    ownerInitiatives?.filter(
      (initiative) => initiative.status === "REJECTED"
    ) ?? [];

  return initiativesAwaitingReview.concat(initiativesRejected);
};

// [TODO: hmachaao]: rework the api reponse structure

export const toInitiatives = (
  enrollments?: EnrollmentData[]
): InitiativeOverview[] => {
  if (!enrollments) return [];
  return enrollments.map((enrollment) => {
    const { initiative } = enrollment;
    return {
      id: initiative.id,
      title: initiative.title,
      category: initiative.category as CategoryData,
      subCategories: initiative?.initiativeCategories.map((sub) => {
        return {
          id: sub.categoryId,
          name: sub.category.name,
        };
      }),
      region: {
        id: "",
        name: initiative.region as string,
      },
    };
  });
};

export const validateUrlAndLabel = (
  url: string,
  label: string | null
): ValidationResult => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );

  const validationResult: ValidationResult = { isValid: true };

  // Initialize validation state
  validationResult.isValid = true;

  // Check URL validity
  if (!urlPattern.test(url)) {
    validationResult.isValid = false;
    validationResult.urlValidation = "invalid url";
  }

  // Check if label is set
  if (label === null || label.trim() === "") {
    validationResult.isValid = false;
    validationResult.labelValidation = "label is mandatory";
  }

  return validationResult;
};
