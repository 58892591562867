import { useState } from "react";
import { ExtendFile } from "@/components";
import { FileRejection } from "react-dropzone";
import { Allocate } from "./Allocate";
import { Allocation, AllocationStep } from "@/interface";
import { Review } from "./Review";
import { useAllocateCycle } from "@/hooks/api";
import { toAlerts, toAllocations } from "@/utils";

type Props = {
  symbol: string;
  onClose: () => void;
  mutate: () => void;
};

export const AllocationUploader = ({ symbol, onClose, mutate }: Props) => {
  const [step, setStep] = useState<AllocationStep>(AllocationStep.ALLOCATE);
  const [extendFile, setExtendFile] = useState<ExtendFile | undefined>(
    undefined
  );
  const [allocations, setAllocations] = useState<Allocation[]>(toAllocations());

  const { trigger } = useAllocateCycle(symbol);

  const handleDrop = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ) => {
    setAllocations([]);
    // Process accepted files
    if (acceptedFiles.length) {
      setExtendFile({ file: acceptedFiles?.[0] });

      const { cycle } = await trigger({ file: acceptedFiles?.[0] });

      setAllocations(toAllocations(cycle.overallAllocation));
      setExtendFile({
        file: acceptedFiles?.[0],
        ...toAlerts(cycle?.file),
      });
    }
    // Handle file rejections
    if (fileRejections.length) {
      setExtendFile({
        file: fileRejections?.[0].file,
        errors: fileRejections?.[0].errors,
      });
    }
  };

  const handleDelete = () => {
    setExtendFile(undefined);
    setAllocations([]);
  };

  return (
    <>
      <Allocate
        extendFile={extendFile}
        step={step}
        allocations={allocations}
        onDrop={handleDrop}
        onDelete={handleDelete}
        onClose={onClose}
        onReview={() => setStep(AllocationStep.REVIEW)}
      />
      <Review
        symbol={symbol}
        step={step}
        allocations={allocations}
        onBack={() => setStep(AllocationStep.ALLOCATE)}
        onDone={() => {
          mutate();
          handleDelete();
          onClose();
        }}
      />
    </>
  );
};
