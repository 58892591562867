import { Box, Stack, StackProps } from "@mui/material";
import { useSearchState } from "@/state";
import { useCategories, useOffices } from "@/hooks/api";
import {
  toCategoryFilterOpts,
  toLocationFilterOpts,
  updateFilters,
} from "@/utils";
import { SearchField, Filter } from "@/components";
import { KeyValuePair } from "@/interface";
import { FiltersRenderer } from "./FiltersRenderer";
import { PropsWithChildren, useEffect } from "react";

type Props = PropsWithChildren<
  {
    keywork?: boolean;
    category?: boolean;
    location?: boolean;
  } & StackProps
>;

export const Filters = ({
  keywork = true,
  category = true,
  location = true,
  children,
  ...rest
}: Props) => {
  const { categories } = useCategories();
  const { data: locations } = useOffices();
  const {
    categories: filteredCategories,
    regions,
    offices,
    setState,
    cleanup,
  } = useSearchState();

  useEffect(() => {
    return () => {
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCategorySelect = (kv: KeyValuePair) => {
    setState({
      categories: updateFilters(kv, filteredCategories),
    });
  };

  const onDelete = (kv: KeyValuePair) => {
    const updateState = (
      kv: KeyValuePair,
      stateKey: string,
      record: Record<string, string>
    ) => {
      if (record[kv.key]) {
        setState({
          [stateKey]: updateFilters(kv, record),
        });
      }
    };

    updateState(kv, "categories", filteredCategories);
    updateState(kv, "regions", regions);
    updateState(kv, "offices", offices);
  };

  const onClear = () => {
    setState({
      categories: {},
      regions: {},
      offices: {},
    });
  };

  return (
    <Stack spacing={2} {...rest}>
      {keywork && (
        <Box display="flex" alignItems="center" gap={1}>
          {children}
          <SearchField
            setKeyword={(keyword) => setState({ keyword })}
            sx={{ ml: "auto", background: "transparent", width: 250 }}
          />
        </Box>
      )}
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
        {category && (
          <Filter
            label="Category"
            options={toCategoryFilterOpts(categories)}
            selected={{ ...filteredCategories }}
            multiple
            onSelectParent={onCategorySelect}
            onSelectChild={onCategorySelect}
          />
        )}
        {location && (
          <Filter
            label="Location"
            selected={{ ...regions, ...offices }}
            options={toLocationFilterOpts(locations)}
            onSelectParent={(kv) =>
              setState({ regions: updateFilters(kv, regions) })
            }
            onSelectChild={(kv) =>
              setState({ offices: updateFilters(kv, offices) })
            }
            multiple
          />
        )}
        <FiltersRenderer
          filters={{ ...filteredCategories, ...regions, ...offices }}
          onDelete={onDelete}
          onClear={onClear}
        />
      </Box>
    </Stack>
  );
};
